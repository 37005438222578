export default {
  TIPO_FOTO: "TIPO_FOTO",
  QTDE_FOTO: "QTDE_FOTO",
  SCAN_QR_CODE: "SCAN_QR_CODE",
  UPLOAD: "UPLOAD",
  EDICAO: "EDICAO",
  PAGAMENTO: "PAGAMENTO",
  IMPRESSAO: "IMPRESSAO",
  AGRADECIMENTO: "AGRADECIMENTO",
  CONCLUIDO: "CONCLUIDO",
  EXPIROU: "EXPIROU",
};
